import axios from 'axios';
import { deleteCookie } from "@/libraries/VueMethods";
const state = {
    userDetail: null,
    userDetailStatus: false,
    orderListPanelStatus: false,
    orderListPanel: null,
    order: null,
    orderStatus: false,
    orderStatistics: null,
    favorite: null,
    favoriteStatus: false,
    transaction: null,
    transactionStatus: false
}

const getters = {
    getTransactionStatus(state) {
        return state.transactionStatus
    },
    getTransaction(state) {
        return state.transaction;
    },
    getFavoriteStatus(state) {
        return state.favoriteStatus
    },
    getFavorite(state) {
        return state.favorite;
    },
    getOrderStatistics(state) {
        return state.orderStatistics
    },
    // دریافت اطلاعات کاربری از ویوایکس
    getUserDetail(state) {
        return state.userDetail;
    },
    //   دریافت استاتوس تغییر وضعیت از ویو ایکس
    getUserDetailStatus() {
        return state.userDetailStatus;
    },
    // دریافت لیست سفارشات از طریق ویو ایکس
    getOrdersInPanel(state) {
        return state.orderListPanel;
    },
    // دریافت استاتوس سفارشات از طریق وی ایکس
    getOrdersInPanelStatus(state) {
        return state.orderListPanelStatus;
    },
    getOrder(state) {
        return state.order;
    },
    getOrderStatus(state) {
        return state.orderStatus;
    }
}

const mutations = {
    setTransactionStatus(state, status) {
        state.transactionStatus = status
    },
    setTransaction(state, list) {
        state.transaction = list;
    },
    setFavoriteStatus(state, status) {
        state.favoriteStatus = status;
    },
    setFavorite(state, list) {
        state.favorite = list
    },
    setOrderStatistics(state, list) {
        state.orderStatistics = list;
    },
    // تنظیم کردن محتوای کاربری
    setUserDetail(state, content) {
        state.userDetail = content;
    },
    //   تنظیم کردن استاتوس وضعیت دریافت محتوای کاربری
    setUserDetailStatus(state, status) {
        state.userDetailStatus = status;
    },
    // تنظیم کردن لیست سفارشات پنل
    setOrderListPanel(state, list) {
        state.orderListPanel = list
    },
    // تنظیم کردن استاتوس دریافت سفارشات پنل
    setOrderListPanelStatus(state, status) {
        state.orderListPanelStatus = status;
    },
    setSingleOrderStatus(state, status) {
        state.orderStatus = status;
    },
    setSingelOrder(state, data) {
        state.order = data;
    },
    resetPanelData(state) {
        let defaultData = {
            userDetail: null,
            userDetailStatus: false,
            orderListPanelStatus: false,
            orderListPanel: null,
            order: null,
            orderStatus: false,
            orderStatistics: null,
            favorite: null,
            favoriteStatus: false,
            transaction: null,
            transactionStatus: false
        };
        for (let [index, value] of Object.entries(defaultData)) {
            state[index] = value;
        }
    }
}

const actions = {
    async getTransactionFromServer({ commit }, page) {
        commit("setTransactionStatus", true)
        await axios.post("/customer/transactions?page=" + page).then(res => {
            commit("setTransaction", res.data.data.transactions);
            commit("setTransactionStatus", false)
        })
    },
    async getFavoriteFromServer({ commit }, page) {
        commit("setFavoriteStatus", true)
        await axios.get("/customer/favorites?page=" + page).then(res => {
            commit("setFavorite", res.data.data)
            commit("setFavoriteStatus", false)

        });
    },
    // دریافت اطلاعات کاربر
    async getUserDetailFromServer({ commit }) {
        commit("setUserDetailStatus", true)
        await axios.get("/customer/profile").then(res => {
            let data = res.data.data.customer;
            data.newsletter = data.newsletter == 1;
            data.foreign_national = data.foreign_national == 1;
            commit("setUserDetail", data)
            commit("setUserDetailStatus", false)

        }).catch(error => {
            //    درصورت دریافت خطا کاربر لاگ اوت شود
            
            deleteCookie("Authorization");
            commit("setLoginStatus", false);
        })
    },

    // دریافت سفارشات کاربر در پنل
    async getOrderInPanelFromServer({ commit }, pack) {
        commit("setOrderListPanelStatus", true);
        await axios.get("/customer/orders?page=" + pack.page + "&status=" + pack.status).then(res => {
            // let orders = res.data.data.orders.data;

            // let orderList = {};
            // orders.forEach((element, index) => {
            //     if (!orderList[element.status]) {
            //         orderList[element.status] = {};
            //     } else {

            //         orderList[element.status][index] = element;
            //     }
            // })

            commit("setOrderListPanel", res.data.data.orders);
            commit("setOrderStatistics", res.data.data.statistics)

            commit("setOrderListPanelStatus", false)

        })
    },
    async getSingleOrderFromServer({ commit }, id) {
        commit("setSingleOrderStatus", true)

        await axios.get("/customer/orders/" + id).then(res => {
            commit("setSingelOrder", res.data.data.order);
            commit("setSingleOrderStatus", false)
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
