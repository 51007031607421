import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

const state = {
  productsAll: null,
  productsStatus: false,
  productsMaxPrice: 50000,
  searchResult: null,
  searchStatus: false,
  mobileProductList: [],
  lastQuery: {},
};

const getters = {
  getLastQuery(state){
    return state.lastQuery
  },
  getProductsAll(state) {
    return state.productsAll;
  },
  getProductsStatus(state) {
    return state.productsStatus;
  },
  getProductsMaxPrice(state) {
    return state.productsMaxPrice;
  },
  // دریافت نتیج جستوجو
  getSearchResult(state) {
    return state.searchResult;
  },
  // دریافت استاتوس نتیج جستوجو
  getSearchStatus(state) {
    return state.searchStatus;
  },
};

const mutations = {
  setLastQuery(state, query) {
    state.lastQuery = query;
  },
  setMobileProductList(state, list) {
    state.mobileProductList = list;
  },
  setProductsAll(state, products) {
    state.productsAll = products;
  },
  setProductsStatus(state, status) {
    state.productsStatus = status;
  },
  setProductsMaxPrice(state, price) {
    state.productsMaxPrice = price;
  },
  // ست کردن نتایج جستوجو
  setSearchResult(state, list) {
    state.searchResult = list;
  },
  setSearchStatus(state, status) {
    state.searchStatus = status;
  },
};

const actions = {
  // اکشن جستوجو در میان محصولات
  async searchInProducts({ commit }, search) {
    commit("setSearchStatus", true);
    await axios.get("/front/products/search?q=" + search).then((response) => {
      commit("setSearchStatus", false);
      commit("setSearchResult", response.data.data.products);
    });
  },
  async getProductsDataFromServer({ commit, state }, payLoad) {
    let query = cloneDeep(payLoad.query);
    delete query.selected_sizes;
    query.attribute_value_id = payLoad.selectedAttributeValueIds;

    commit("setProductsStatus", true);
    try {
      const response = await axios.get("front/products", { params: query });
      commit("setProductsAll", response.data.data.products);
      if (window.innerWidth > 768) {
        setTimeout(() => {
          document.documentElement.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        });
      } else {
        let allowChangeList = false;
        for (const k in query) {
          if (!isEqual(query[k], state.lastQuery[k]) && k != "page") {
            allowChangeList = true;
            break;
          }
        }
        
        commit("setLastQuery", query);
        let list = allowChangeList
          ? response.data.data.products.data
          : [...state.mobileProductList, ...response.data.data.products.data];
        commit("setMobileProductList", list);
        return list;
      }
      if (response.data.data.priceFilter.max_price > 50000) {
        let max =
          Math.ceil(response.data.data.priceFilter.max_price / 1000) * 1000;
        commit("setProductsMaxPrice", max);
      }
      commit("setProductsStatus", false);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
